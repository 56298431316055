.nk {
  &-sidebar {
    position: absolute;
    height: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    width: $sidebar-width-normal;
    transform: translateX(-100%);
    transition: $transition;
    background-color: $sidebar-bg;
    z-index: 1026;
    box-shadow: $sidebar-box-shadow;
    &.sidebar-active {
      transform: none;
    }
    + .nk-wrap {
      transition: $transition;
    }
    &-brand {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      .logo-textimg {
        max-width: 100%;
        width: 7rem;
        margin-left: 0.5rem;
      }
    }
    .nk-menu-text,
    .nk-sidebar-brand {
      transition: $transition;
    }
    &.active {
      transform: translateX(0);
    }
    &-fixed {
      position: fixed;
      max-height: 100vh;
    }
    &-head {
      display: flex;
      align-items: center;
      padding: $sidebar-head-gap-y $sidbar-head-gap-x;
      overflow: hidden;
      width: $sidebar-width-normal;
    }
    &-body {
      position: relative;
      height: 100%;
    }
    &-content {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 80px);
      .nk-sidebar-menu {
        &[data-simplebar] {
          height: 100%;
        }
      }
    }
    &.is-theme {
      background-color: $sidebar-dark;
    }
  }
}

$sidebar-overlay-transition: 400ms ease;

.sidebar {
  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $sidebar-overlay;
    z-index: 1025;
    animation: overlay-fade-out $sidebar-overlay-transition 1;
  }
  &-shown {
    overflow: hidden;
    .sidebar-overlay {
      animation: overlay-fade-in $sidebar-overlay-transition 1;
    }
  }
}

@keyframes overlay-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes overlay-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@each $point in $points {
  @include media-breakpoint-up($point) {
    [data-sidebar-collapse="#{$point}"] {
      .nk {
        &-sidebar {
          transform: translateX(0);
          &-toggle {
            display: none;
            .logo-textimg {
              display: none;
            }
          }
          + .nk-wrap {
            padding-left: $sidebar-width-normal;
            > .nk-header-fixed {
              left: $sidebar-width-normal;
            }
          }
        }
      }
    }
  }
}
