.profile-photo-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.profile-photo-wrapper:hover {
    transform: scale(1.05);
}

.hover-text {
    position: absolute;
    top: 0;
    left: 0;
    height: 6rem;
    width: 6rem;
    text-align: center;
    line-height: 7;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    color: white;
    font-size: 0.75rem;
    padding: 0.5rem;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.profile-photo-wrapper:hover .hover-text {
    opacity: 1;
}

.dropzone {
    cursor: pointer;
    background: #f8f9fa;
    border: 2px dashed #dee2e6 !important;
    transition: background 0.3s ease;
}

.dropzone:hover {
    background: #e9ecef;
}

.cross-arrow {
    top: -5px;
    right: -5px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    z-index: 10;
}

/* Crazy Dropdown Styles */
/* Crazy Dropdown Styles */
.dropdown-toggle:hover+.dropdown-menu,
.dropdown-menu:hover {
    display: block;
}

.crazy-link {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    color: #fff !important;
}

.crazy-link:hover {
    background: linear-gradient(45deg, #5f45ac, #560875);
    transform: scale(1.1);
    box-shadow: 0 4px 15px rgba(28, 11, 68, 0.511);
}

/* Animation for Dropdown */
@keyframes crazyDropdown {
    from {
        transform: translateY(-10px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Make sure dropdown is initially hidden */
.dropdown-menu {
    display: none;
    animation: crazyDropdown 0.5s ease-in-out;
}

/* Active Tab Color (Grey) */
.crazy-link.active {
    background-color: #2e314a !important;

    color: white !important;
    /* White text on active tab */
}

