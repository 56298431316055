@use "sass:math";
@use "sass:list";
$select-arrow-width-half: math.div(pxToRem(list.nth($form-select-bg-size, 1)),2);
.form{
    &-control{
        &:not([type="file"]):read-only {
            background-color:  $field-readonly-bg;
        }
        &-wrap{
            position: relative;
        }
        &-hint{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: $base-light;
            padding:0 $field-padding-x;
            font-size: $field-hint-font-size;
        }
        &-icon{
            display: flex;
            align-items: center;
            position: absolute;
            font-size: $field-icon-font-size;
            left: 0;
            top: 0;
            padding: 0 $field-padding-x*.75;
            height: $field-height;
            &.start{
                + .form-control{
                    padding-left: $field-icon-font-size + $field-padding-x*1.3;
                }
            }
            &.end{
                left: auto;
                right: 0;
                + .form-control{
                    padding-right: $field-icon-font-size + $field-padding-x*1.3;
                }
            }
            &.sm{
                padding: 0 $field-padding-x-sm*.75;
                height: $field-height-sm;
                &.start{
                    + .form-control{
                        padding-left: $field-icon-font-size + $field-padding-x-sm*1.3;
                    }
                }
                &.end{
                    + .form-control{
                        padding-right: $field-icon-font-size + $field-padding-x-sm*1.3;
                    }
                }
            }
            &.md{
                padding: 0 $field-padding-x-md*.75;
                height: $field-height-md;
                &.start{
                    + .form-control{
                        padding-left: $field-icon-font-size + $field-padding-x-md*1.3;
                    }
                }
                &.end{
                    + .form-control{
                        padding-right: $field-icon-font-size + $field-padding-x-md*1.3;
                    }
                }
            }
            &.lg{
                padding: 0 $field-padding-x-lg*.75;
                height: $field-height-lg;
                &.start{
                    + .form-control{
                        padding-left: $field-icon-font-size + $field-padding-x-lg*1.3;
                    }
                }
                &.end{
                    + .form-control{
                        padding-right: $field-icon-font-size + $field-padding-x-lg*1.3;
                    }
                }
            }
        }
        &-plaintext{
            &:focus{
                box-shadow: none;
                border-color: transparent;
            }
            &.form-control{
                &-sm{
                    padding-top: $field-padding-y-sm;
                    padding-bottom: $field-padding-y-sm;
                }
                &-md{
                    padding-top: $field-padding-y-md;
                    padding-bottom: $field-padding-y-md;
                }
                &-lg{
                    padding-top: $field-padding-y-lg;
                    padding-bottom: $field-padding-y-lg;
                }
            }
        }
    }
    &-select{
        padding-right: $field-height;
        background-position: right math.div($field-height,2) - $select-arrow-width-half center;
        &-sm{
            background-position: right math.div($field-height-sm,2) - $select-arrow-width-half center;
            padding-right: $field-height-sm;
        }
        &-lg{
            background-position: right math.div($field-height-lg,2) - $select-arrow-width-half center;
            padding-right: $field-height-lg;
        }
    }
    &-note{
        font-size: $field-note-font-size;
        color: $field-note-color;
    }
    &-inline{
        display: inline-flex;
        flex-wrap: wrap;
    }
    &-error{
        font-size: .875rem;
        color: $danger;
        padding-top: 0.25rem;
        .floating-feedbacks &{
            position: absolute;
            color: $white;
            background: $danger;
            padding: 0 .375rem;
            border-radius: 2px;
            font-size: .75rem;
            right: 0;
            bottom: 100%;
            margin-bottom: 0.4rem;
            &:after{
                position: absolute;
                content: '';
                right: .5rem;
                bottom: -6px;
                height: 0;
                width: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 6px solid $danger;
            }
        }
    }

    &-check-wrap{
        .form-error{
            .floating-feedbacks &{
                margin-bottom: 0.325rem;
                left: 0;
                right: auto;
                &:after{
                    left: .375rem;
                }
            }
        }
    }
}

textarea {
    &.form-control {
      min-height: 2.625rem;
    }
}

.password-toggle{
    color: $base-light;
    .active{
        display: block;
    }
    .inactive{
        display: none;
    }
    &.is-shown{
        .active{
            display: none;
        }
        .inactive{
            display: block;
        }
    }
}