//LOADING SPLASH SCREEN

.cursor-pointer{
cursor: pointer !important;
}

.preloader {
    background-color: #08162b;
    padding: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    z-index: 99999;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

// Flash screen styles
.flash-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


// Flash body container styles
.flash-body {
    position: relative;
    z-index: 0;
    width: 350px;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    background-color: #ffffff;
    color: #ffffff;

    &::before {
        content: "Please wait while loading";
        position: absolute;
        z-index: -2;
        left: -50%;
        top: -50%;
        width: 200%;
        height: 260%;
        background-color: #ffffff;
        background-repeat: no-repeat;
        background-size: 50% 50%, 50% 50%;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        background-image:
            linear-gradient(45deg, #ffffff, #068f8e 50%),
            linear-gradient(135deg, #ffffff, #09aeae 50%);
        animation: rotate 2s linear infinite;
    }

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 6px;
        top: 6px;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        background: #08162b;
        border-radius: 5px;
    }
}

.text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* Prevent overflow for animations */
}

.club-name {
    font-weight: 600;
    text-align: center;
    letter-spacing: 2px;
    white-space: nowrap;
    color: #f1f1f1;
    display: inline-block;
    opacity: 0;
    /* Start with invisible text */
    animation: slide 8s ease-in-out infinite;
}

@keyframes slide {
    0% {
        transform: translateX(-100%) scale(0.5);
        opacity: 0;
    }

    20% {
        transform: translateX(0) scale(1);
        opacity: 1;
    }

    40% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1);
    }

    70% {
        transform: translateY(-10px);
    }

    80% {
        transform: translateY(0);
    }

    100% {
        transform: translateX(100%) scale(0.5);
        opacity: 0;
    }
}



// Keyframes for animation (if you want to add it here)
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.truncate-text {
    max-width: 100px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  
  .truncate-text .text.smaller {
    display: block;
  }
  